$fa-font-path: "../fonts";
@import "@fortawesome/fontawesome-pro/scss/fontawesome";
@import "@fortawesome/fontawesome-pro/scss/solid";
@import "@fortawesome/fontawesome-pro/scss/regular";
@import "@fortawesome/fontawesome-pro/scss/light";
@import "@fortawesome/fontawesome-pro/scss/brands";/**
 * Handles all font loading separate from main theme so that fonts are not
 * render-blocking. This file is loaded separately from theme.css!
 *
 * NOTE: This file must exist and the font families chosen in themeconfig.json
 * will be loaded automatically by the build process.
 *
 * Place @font-face directives at the top of this file
 */
//@import url("https://use.typekit.net/esl4bit.css");
// Default font style, mostly used for icons baked into the templates
.fa {
	// Font Awesome defaults to fa = fas
	//@extend .far;
}

.fa, .fas, .far, .fal, .fab {

	// While fonts are loading
	.wf-loading & {
		// Prevent FOUT
		color: transparent;
	}
}

/* Webfont: Braxton-Regular */
@font-face {
    font-family: 'Braxton';
    src: url('../fonts-custom/Braxton-Regular-2.eot'); /* IE9 Compat Modes */
    src: url('../fonts-custom/Braxton-Regular-2.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts-custom/Braxton-Regular-2.woff') format('woff'), /* Modern Browsers */
         url('../fonts-custom/Braxton-Regular-2.ttf') format('truetype'); /* Safari, Android, iOS */
             font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'magallanes';
    src: url('../fonts-custom/MagallanesRegular.eot');
    src: local('Magallanes Regular'), local('MagallanesRegular'),
        url('../fonts-custom/MagallanesRegular.eot?#iefix') format('embedded-opentype'),
        url('../fonts-custom/MagallanesRegular.woff') format('woff'),
        url('../fonts-custom/MagallanesRegular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'magallanes';
    src: url('../fonts-custom/MagallanesRegular-Italic.eot');
    src: local('Magallanes Regular Italic'), local('MagallanesRegular-Italic'),
        url('../fonts-custom/MagallanesRegular-Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts-custom/MagallanesRegular-Italic.woff') format('woff'),
        url('../fonts-custom/MagallanesRegular-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}


@font-face {
    font-family: 'magallanes';
    src: url('../fonts-custom/MagallanesBold.eot');
    src: local('Magallanes Bold'), local('MagallanesBold'),
        url('../fonts-custom/MagallanesBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts-custom/MagallanesBold.woff') format('woff'),
        url('../fonts-custom/MagallanesBold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'magallanes';
    src: url('../fonts-custom/MagallanesBold-Italic.eot');
    src: local('Magallanes Bold Italic'), local('MagallanesBold-Italic'),
        url('../fonts-custom/MagallanesBold-Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts-custom/MagallanesBold-Italic.woff') format('woff'),
        url('../fonts-custom/MagallanesBold-Italic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'magallanes';
    src: url('../fonts-custom/MagallanesBlack.eot');
    src: local('Magallanes Black'), local('MagallanesBlack'),
        url('../fonts-custom/MagallanesBlack.eot?#iefix') format('embedded-opentype'),
        url('../fonts-custom/MagallanesBlack.woff') format('woff'),
        url('../fonts-custom/MagallanesBlack.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}



// @font-face {
// font-family:"magallanes";
// src:url("https://use.typekit.net/af/8850db/00000000000000007735abad/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i1&v=3") format("woff2"),url("https://use.typekit.net/af/8850db/00000000000000007735abad/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i1&v=3") format("woff"),url("https://use.typekit.net/af/8850db/00000000000000007735abad/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i1&v=3") format("opentype");
// font-display:auto;font-style:italic;font-weight:100;font-stretch:normal;
// }

// @font-face {
// font-family:"magallanes";
// src:url("https://use.typekit.net/af/ac4418/00000000000000007735abba/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/ac4418/00000000000000007735abba/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/ac4418/00000000000000007735abba/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
// font-display:auto;font-style:italic;font-weight:400;font-stretch:normal;
// }

// @font-face {
// font-family:"magallanes";
// src:url("https://use.typekit.net/af/bcad73/00000000000000007735abbc/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/bcad73/00000000000000007735abbc/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/bcad73/00000000000000007735abbc/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
// font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
// }

// @font-face {
// font-family:"magallanes";
// src:url("https://use.typekit.net/af/4a8114/00000000000000007735abc5/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/4a8114/00000000000000007735abc5/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/4a8114/00000000000000007735abc5/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
// font-display:auto;font-style:normal;font-weight:700;font-stretch:normal;
// }

// @font-face {
// font-family:"magallanes";
// src:url("https://use.typekit.net/af/1f1cd5/00000000000000007735abc7/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/1f1cd5/00000000000000007735abc7/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/1f1cd5/00000000000000007735abc7/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
// font-display:auto;font-style:italic;font-weight:700;font-stretch:normal;
// }

// @font-face {
// font-family:"magallanes";
// src:url("https://use.typekit.net/af/2c5321/00000000000000007735abcb/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("woff2"),url("https://use.typekit.net/af/2c5321/00000000000000007735abcb/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("woff"),url("https://use.typekit.net/af/2c5321/00000000000000007735abcb/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("opentype");
// font-display:auto;font-style:normal;font-weight:900;font-stretch:normal;
// }

.tk-magallanes { font-family: "magallanes",sans-serif; }
